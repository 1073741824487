/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n": types.PresetsDocument,
    "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      generationId\n      progress\n      notifyEmail\n      status\n      errorMessages\n      inputFileUrl\n      feedback {\n        rating\n        message\n      }\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        aspectRatio\n        variationId\n        imagePosition\n        presetId\n        customPrompt\n        seed\n        margin\n        task {\n          _id\n          type\n          status\n          jsonResult\n          parsedResult\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.GetAiAnimatedElementsGenerationDocument,
    "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        generationId\n        inputBucketInfo {\n          bucketName\n          filePath\n        }\n        status\n        presets {\n          aspectRatio\n          imagePosition\n          presetId\n          task {\n            status\n            parsedResult\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n": types.MyGenerationsDocument,
    "\n  query GenerationCount {\n    myAiGenerations(limit: 1) {\n      data {\n        generationId\n      } \n    }\n  }\n": types.GenerationCountDocument,
    "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      generationId\n      notifyEmail\n      inputFileUrl\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        presetId\n        seed\n        task {\n          _id\n          status\n          jsonResult\n          type\n          updatedAt\n          parsedResult\n          jsonPayload\n          createdAt\n        }\n      }\n    }\n  }\n": types.CreateAiAnimatedElementsWithPresetsDocument,
    "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n": types.NotifyEmailOnGenerationCompleteDocument,
    "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n": types.SendFeedbackForGenerationDocument,
    "\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n    }\n  }\n": types.GenerateAiAnimatedPictureInputFromPictureUrlDocument,
    "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n": types.CancelGenerationDocument,
    "\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n": types.SubscribeToWaitingListDocument,
    "\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n": types.CreateProjectFromGenerationDocument,
    "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n": types.GetAiTokensPlansDocument,
    "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n": types.GetOneOffCheckoutSessionDocument,
    "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      generationId\n    }\n  }\n": types.CreateGenerationVariationDocument,
    "\n  query GetTeamBrandKit($teamId: ID!) {\n    getTeamBrandKit(teamId: $teamId) {\n      colors\n      fonts {\n        _id\n        displayName\n        fileName\n        bucketName\n      }\n      logos {\n        _id\n        fileName\n        bucketName\n      }\n    }\n  }\n": types.GetTeamBrandKitDocument,
    "\n  mutation CreateTeamBrandKitColor($teamId: ID!, $color: String!) {\n    createTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n": types.CreateTeamBrandKitColorDocument,
    "\n  mutation RemoveTeamBrandKitColor($teamId: ID!, $color: String!) {\n    removeTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n": types.RemoveTeamBrandKitColorDocument,
    "\n  mutation RemoveTeamBrandKitFont($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitFont(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n": types.RemoveTeamBrandKitFontDocument,
    "\n  mutation RemoveTeamBrandKitLogo($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitLogo(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n": types.RemoveTeamBrandKitLogoDocument,
    "\nmutation CreateTeamBrandKitFileForUpload($teamId: ID!, $fileType: BrandkitFileType!, $fileName: String!) {\n  createTeamBrandKitFileForUpload(teamId: $teamId, fileType: $fileType, fileName: $fileName) {\n    bucketName\n    fileName\n    validatedAt\n    _id\n  }\n}\n": types.CreateTeamBrandKitFileForUploadDocument,
    "\n  query MyTeamProjects($teamId: String!, $filters: MyProjectFiltersV2) {\n    myTeamProjectsLight(teamId: $teamId, filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        isOwner\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n": types.MyTeamProjectsDocument,
    "\n  mutation UnshareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    unshareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n": types.UnshareProjectWithTeamDocument,
    "\n  query MyPersonalProjectsLight($filters: MyProjectFiltersV2) {\n    myPersonalProjectsLightV2(filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n": types.MyPersonalProjectsLightDocument,
    "\n    mutation RemoveProject($projectId: ID!) {\n      removeProject(projectId: $projectId) {\n        __typename\n        deletedProjectId\n      }\n    }\n  ": types.RemoveProjectDocument,
    "\n  mutation ShareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    shareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n": types.ShareProjectWithTeamDocument,
    "\n  mutation DuplicatePersonalProjectLight($projectId: ID!) {\n    duplicatePersonalProjectLight(projectId: $projectId) {\n      __typename\n      _id\n      savedAt\n      projectId\n      platform\n      thumbnailUrl\n      thumbHash\n      exportId\n      videoInfo {\n        bucketName\n        path\n      }\n      format {\n        width\n        height\n      }\n    }\n  }\n": types.DuplicatePersonalProjectLightDocument,
    "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n": types.GetUserProfilePictureUploadInfoDocument,
    "\n  query GetTeamProfilePictureUploadInfo($teamId: ID!) {\n    getTeamProfilePictureUploadInfo(teamId: $teamId) {\n      bucketName\n      uploadPath\n    }\n  }\n": types.GetTeamProfilePictureUploadInfoDocument,
    "\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n": types.GetProPlanPricesDocument,
    "\n  query GetTeamPlanPrices {\n    getTeamPlanPrices {\n      price\n      periodicity\n      currency {\n        symbol\n        isoCode\n        showBefore\n      }\n    }\n  }\n": types.GetTeamPlanPricesDocument,
    "\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n": types.GetCheckoutSessionDocument,
    "\n  query GetProfile {\n    getProfile {\n      uid\n      teams { \n        id\n        name\n        isPro\n        currentUserTeamRole\n        profilePictureUrl\n      }\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n": types.GetProfileDocument,
    "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  ": types.RemoveProfilePictureDocument,
    "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  ": types.SetNameDocument,
    "\n  mutation CreateShareableLink($exportId: String!) {\n    createShareableLink(exportId: $exportId) {\n      url\n    }\n  }\n": types.CreateShareableLinkDocument,
    "\n  query GetShareableLink($uid: String!) {\n    getShareableLink(uid: $uid) {\n      signedUrl\n      uid\n      exportId\n      allowRemix\n      videoWidth\n      videoHeight\n    }\n  }\n": types.GetShareableLinkDocument,
    "\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n": types.GetSubscriptionDocument,
    "\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n": types.HandleCheckoutSessionSuccessDocument,
    "\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n": types.QueryDocument,
    "\n  query GetTeam {\n    getProfile {\n      uid\n      teams {\n        id\n        name\n        isPro\n        invitations\n        currentUserTeamRole\n        profilePictureUrl\n        members {\n          role\n          user {\n            name\n            email\n            profilePictureUrl\n            uid\n          }\n        }\n      }\n    }\n  }\n": types.GetTeamDocument,
    "\n  mutation RemoveTeamPicture($teamId: ID!) {\n    removeTeamProfilePicture(teamId: $teamId)\n  }\n": types.RemoveTeamPictureDocument,
    "\n  mutation UpdateTeamName($teamId: ID!, $name: String!) {\n    updateTeamName(teamId: $teamId, name: $name) {\n      id\n      name\n    }\n  }\n": types.UpdateTeamNameDocument,
    "\n   mutation DeleteTeam($teamId: ID!) {\n    deleteTeam(teamId: $teamId) {\n      id\n    }\n  }\n": types.DeleteTeamDocument,
    "\n  mutation LeaveTeam($teamId: ID!) {\n    leaveTeam(teamId: $teamId) {\n      id\n    }\n  }\n": types.LeaveTeamDocument,
    "\n  mutation ManageInvitations($teamId: ID!, $push: [String], $pull: [String]) {\n    manageInvitations(teamId: $teamId, push: $push, pull: $pull) {\n      id\n      invitations\n    }\n  }\n": types.ManageInvitationsDocument,
    "\n  mutation ChangeTeamMemberRole($teamId: ID!, $userId: ID!, $role: TeamRole!) {\n    changeTeamMemberRole(teamId: $teamId, userId: $userId, role: $role) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n": types.ChangeTeamMemberRoleDocument,
    "\n  mutation RemoveTeamMember($teamId: ID!, $userId: ID!) {\n    removeTeamMember(teamId: $teamId, userId: $userId) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n": types.RemoveTeamMemberDocument,
    "\n  mutation GenerateTeamInvitationLink($teamId: ID!) {\n    generateTeamInvitationLink(teamId: $teamId)\n  }\n": types.GenerateTeamInvitationLinkDocument,
    "\n  mutation JoinTeam($inviteLinkURL: String!) {\n    joinTeam(inviteLinkURL: $inviteLinkURL) {\n      id\n    }\n  }\n": types.JoinTeamDocument,
    "\n  mutation CreateTeam($name: String) {\n    createTeam(name: $name) {\n      id\n      name\n      logo {\n        bucketName\n        fileName\n      }\n  }\n}\n": types.CreateTeamDocument,
    "\n  query ListTrendySongTemplateCategoriesWithTemplates {\n    listTrendySongTemplateCategoriesWithTemplates {\n      category {\n        categoryId\n        label\n      }\n      items {\n        _id\n        hasSound\n        isPro\n        isTemplateNew\n        maxMediaNb\n        minMediaNb\n        thumbnailUrl\n        videoUrl\n      }\n    }\n  }\n": types.ListTrendySongTemplateCategoriesWithTemplatesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query Presets($filters: AiAnimatedElementPresetsFilters) {\n    getAiAnimatedElementPresets(filters: $filters) {\n      presets {\n        presetId\n        isPro\n        isPresetNew\n        previewVideoUrl\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      generationId\n      progress\n      notifyEmail\n      status\n      errorMessages\n      inputFileUrl\n      feedback {\n        rating\n        message\n      }\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        aspectRatio\n        variationId\n        imagePosition\n        presetId\n        customPrompt\n        seed\n        margin\n        task {\n          _id\n          type\n          status\n          jsonResult\n          parsedResult\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAiAnimatedElementsGeneration($generationId: ID!) {\n    getAiAnimatedElementsGeneration(generationId: $generationId) {\n      generationId\n      progress\n      notifyEmail\n      status\n      errorMessages\n      inputFileUrl\n      feedback {\n        rating\n        message\n      }\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        aspectRatio\n        variationId\n        imagePosition\n        presetId\n        customPrompt\n        seed\n        margin\n        task {\n          _id\n          type\n          status\n          jsonResult\n          parsedResult\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        generationId\n        inputBucketInfo {\n          bucketName\n          filePath\n        }\n        status\n        presets {\n          aspectRatio\n          imagePosition\n          presetId\n          task {\n            status\n            parsedResult\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query MyGenerations($limit: Int, $cursor: String) {\n    myAiGenerations(limit: $limit, cursor: $cursor) {\n      data {\n        generationId\n        inputBucketInfo {\n          bucketName\n          filePath\n        }\n        status\n        presets {\n          aspectRatio\n          imagePosition\n          presetId\n          task {\n            status\n            parsedResult\n          }\n        }\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GenerationCount {\n    myAiGenerations(limit: 1) {\n      data {\n        generationId\n      } \n    }\n  }\n"): (typeof documents)["\n  query GenerationCount {\n    myAiGenerations(limit: 1) {\n      data {\n        generationId\n      } \n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      generationId\n      notifyEmail\n      inputFileUrl\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        presetId\n        seed\n        task {\n          _id\n          status\n          jsonResult\n          type\n          updatedAt\n          parsedResult\n          jsonPayload\n          createdAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAiAnimatedElementsWithPresets(\n    $inputFileUrl: String!\n    $presets: [PresetConfig!]\n    $inputBucketInfo: BucketInfoInput\n  ) {\n    createAiAnimatedElementsWithPresets(\n      inputFileUrl: $inputFileUrl\n      presets: $presets\n      inputBucketInfo: $inputBucketInfo\n    ) {\n      generationId\n      notifyEmail\n      inputFileUrl\n      inputBucketInfo {\n        filePath\n        bucketName\n      }\n      presets {\n        presetId\n        seed\n        task {\n          _id\n          status\n          jsonResult\n          type\n          updatedAt\n          parsedResult\n          jsonPayload\n          createdAt\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"): (typeof documents)["\n  mutation NotifyEmailOnGenerationComplete(\n    $generationId: ID!\n  ) {\n    notifyEmailOnGenerationComplete(\n      generationId: $generationId\n    ) {\n      generationId\n      errorMessages\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation SendFeedbackForGeneration(\n    $generationId: ID!\n    $rating: Int!\n    $message: String\n  ) {\n    sendFeedbackForGeneration(\n      generationId: $generationId\n      rating: $rating\n      message: $message\n    ) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n    }\n  }\n"): (typeof documents)["\n  query GenerateAiAnimatedPictureInputFromPictureUrl($pictureUrl: String!) {\n    generateAiAnimatedPictureInputFromPictureUrl(pictureUrl: $pictureUrl) {\n      removedBackgroundPictureUrl\n      bucketName\n      filePath\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CancelGeneration($generationId: ID!) {\n    cancelGeneration(generationId: $generationId) {\n      generationId\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n"): (typeof documents)["\n  mutation SubscribeToWaitingList($email: String!) {\n    subscribeToWaitingList(email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProjectFromGeneration($generationId: ID!) {\n    createProjectFromGeneration(generationId: $generationId) {\n      _id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"): (typeof documents)["\n  query GetAiTokensPlans {\n    getAiTokensPlans {\n      productId\n      name\n      currency {\n        isoCode\n        symbol\n        showBefore\n      }\n      tokensGiven\n      price\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetOneOffCheckoutSession($productId: String!) {\n    getOneOffCheckoutSession(productId: $productId) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      generationId\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGenerationVariation($generationId: ID!) {\n    createGenerationVariation(generationId: $generationId) {\n      generationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTeamBrandKit($teamId: ID!) {\n    getTeamBrandKit(teamId: $teamId) {\n      colors\n      fonts {\n        _id\n        displayName\n        fileName\n        bucketName\n      }\n      logos {\n        _id\n        fileName\n        bucketName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTeamBrandKit($teamId: ID!) {\n    getTeamBrandKit(teamId: $teamId) {\n      colors\n      fonts {\n        _id\n        displayName\n        fileName\n        bucketName\n      }\n      logos {\n        _id\n        fileName\n        bucketName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTeamBrandKitColor($teamId: ID!, $color: String!) {\n    createTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n"): (typeof documents)["\n  mutation CreateTeamBrandKitColor($teamId: ID!, $color: String!) {\n    createTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTeamBrandKitColor($teamId: ID!, $color: String!) {\n    removeTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n"): (typeof documents)["\n  mutation RemoveTeamBrandKitColor($teamId: ID!, $color: String!) {\n    removeTeamBrandKitColor(teamId: $teamId, color: $color) \n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTeamBrandKitFont($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitFont(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveTeamBrandKitFont($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitFont(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTeamBrandKitLogo($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitLogo(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveTeamBrandKitLogo($teamId: ID!, $fileId: ID!) {\n    removeTeamBrandKitLogo(teamId: $teamId, fileId: $fileId) {\n      _id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateTeamBrandKitFileForUpload($teamId: ID!, $fileType: BrandkitFileType!, $fileName: String!) {\n  createTeamBrandKitFileForUpload(teamId: $teamId, fileType: $fileType, fileName: $fileName) {\n    bucketName\n    fileName\n    validatedAt\n    _id\n  }\n}\n"): (typeof documents)["\nmutation CreateTeamBrandKitFileForUpload($teamId: ID!, $fileType: BrandkitFileType!, $fileName: String!) {\n  createTeamBrandKitFileForUpload(teamId: $teamId, fileType: $fileType, fileName: $fileName) {\n    bucketName\n    fileName\n    validatedAt\n    _id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyTeamProjects($teamId: String!, $filters: MyProjectFiltersV2) {\n    myTeamProjectsLight(teamId: $teamId, filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        isOwner\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query MyTeamProjects($teamId: String!, $filters: MyProjectFiltersV2) {\n    myTeamProjectsLight(teamId: $teamId, filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        isOwner\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UnshareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    unshareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n"): (typeof documents)["\n  mutation UnshareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    unshareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyPersonalProjectsLight($filters: MyProjectFiltersV2) {\n    myPersonalProjectsLightV2(filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query MyPersonalProjectsLight($filters: MyProjectFiltersV2) {\n    myPersonalProjectsLightV2(filters: $filters) {\n      data {\n        _id\n        projectId\n        platform\n        thumbnailUrl\n        thumbHash\n        exportId\n        videoInfo {\n          bucketName\n          path\n        }\n        format {\n          width\n          height\n        }\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveProject($projectId: ID!) {\n      removeProject(projectId: $projectId) {\n        __typename\n        deletedProjectId\n      }\n    }\n  "): (typeof documents)["\n    mutation RemoveProject($projectId: ID!) {\n      removeProject(projectId: $projectId) {\n        __typename\n        deletedProjectId\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ShareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    shareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n"): (typeof documents)["\n  mutation ShareProjectWithTeam($projectId: ID!, $teamId: ID!) {\n    shareProjectWithTeam(projectId: $projectId, teamId: $teamId) {\n      projectId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DuplicatePersonalProjectLight($projectId: ID!) {\n    duplicatePersonalProjectLight(projectId: $projectId) {\n      __typename\n      _id\n      savedAt\n      projectId\n      platform\n      thumbnailUrl\n      thumbHash\n      exportId\n      videoInfo {\n        bucketName\n        path\n      }\n      format {\n        width\n        height\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DuplicatePersonalProjectLight($projectId: ID!) {\n    duplicatePersonalProjectLight(projectId: $projectId) {\n      __typename\n      _id\n      savedAt\n      projectId\n      platform\n      thumbnailUrl\n      thumbHash\n      exportId\n      videoInfo {\n        bucketName\n        path\n      }\n      format {\n        width\n        height\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"): (typeof documents)["\n  query GetUserProfilePictureUploadInfo {\n    getUserProfilePictureUploadInfo {\n      bucketName\n      uploadPath\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTeamProfilePictureUploadInfo($teamId: ID!) {\n    getTeamProfilePictureUploadInfo(teamId: $teamId) {\n      bucketName\n      uploadPath\n    }\n  }\n"): (typeof documents)["\n  query GetTeamProfilePictureUploadInfo($teamId: ID!) {\n    getTeamProfilePictureUploadInfo(teamId: $teamId) {\n      bucketName\n      uploadPath\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProPlanPrices {\n    getProPlanPrices {\n      periodicity\n      price\n      currency {\n        symbol\n        showBefore\n        isoCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTeamPlanPrices {\n    getTeamPlanPrices {\n      price\n      periodicity\n      currency {\n        symbol\n        isoCode\n        showBefore\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTeamPlanPrices {\n    getTeamPlanPrices {\n      price\n      periodicity\n      currency {\n        symbol\n        isoCode\n        showBefore\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n"): (typeof documents)["\n  query GetCheckoutSession($checkoutSessionInput: CheckoutSessionInput!) {\n    getCheckoutSession(checkoutSessionInput: $checkoutSessionInput) {\n      sessionId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProfile {\n    getProfile {\n      uid\n      teams { \n        id\n        name\n        isPro\n        currentUserTeamRole\n        profilePictureUrl\n      }\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n"): (typeof documents)["\n  query GetProfile {\n    getProfile {\n      uid\n      teams { \n        id\n        name\n        isPro\n        currentUserTeamRole\n        profilePictureUrl\n      }\n      email\n      profilePictureUrl\n      name\n      isPro\n      aiCredits\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "): (typeof documents)["\n    mutation RemoveProfilePicture {\n      removeProfilePicture {\n        message\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation SetName($name: String!) {\n      setName(name: $name) {\n        user {\n          __typename\n          uid\n          name\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateShareableLink($exportId: String!) {\n    createShareableLink(exportId: $exportId) {\n      url\n    }\n  }\n"): (typeof documents)["\n  mutation CreateShareableLink($exportId: String!) {\n    createShareableLink(exportId: $exportId) {\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetShareableLink($uid: String!) {\n    getShareableLink(uid: $uid) {\n      signedUrl\n      uid\n      exportId\n      allowRemix\n      videoWidth\n      videoHeight\n    }\n  }\n"): (typeof documents)["\n  query GetShareableLink($uid: String!) {\n    getShareableLink(uid: $uid) {\n      signedUrl\n      uid\n      exportId\n      allowRemix\n      videoWidth\n      videoHeight\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubscription {\n    getProfile {\n      uid\n      isPro\n      teams { \n        id\n        isPro\n        billingInfos {\n          store\n          validUntil\n        }\n      }\n      billingInfos {\n        store\n        validUntil\n        transactionMetadata {\n          transactionId\n          purchasedAtMs\n          productId\n          periodType\n          originalTransactionId\n          eventId\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"): (typeof documents)["\n  mutation HandleCheckoutSessionSuccess($checkoutSessionId: String!) {\n    handleCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"): (typeof documents)["\n  query Query($portalSessionInput: PortalSessionInput!) {\n    getStripePortalSession(portalSessionInput: $portalSessionInput)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTeam {\n    getProfile {\n      uid\n      teams {\n        id\n        name\n        isPro\n        invitations\n        currentUserTeamRole\n        profilePictureUrl\n        members {\n          role\n          user {\n            name\n            email\n            profilePictureUrl\n            uid\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTeam {\n    getProfile {\n      uid\n      teams {\n        id\n        name\n        isPro\n        invitations\n        currentUserTeamRole\n        profilePictureUrl\n        members {\n          role\n          user {\n            name\n            email\n            profilePictureUrl\n            uid\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTeamPicture($teamId: ID!) {\n    removeTeamProfilePicture(teamId: $teamId)\n  }\n"): (typeof documents)["\n  mutation RemoveTeamPicture($teamId: ID!) {\n    removeTeamProfilePicture(teamId: $teamId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTeamName($teamId: ID!, $name: String!) {\n    updateTeamName(teamId: $teamId, name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeamName($teamId: ID!, $name: String!) {\n    updateTeamName(teamId: $teamId, name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   mutation DeleteTeam($teamId: ID!) {\n    deleteTeam(teamId: $teamId) {\n      id\n    }\n  }\n"): (typeof documents)["\n   mutation DeleteTeam($teamId: ID!) {\n    deleteTeam(teamId: $teamId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LeaveTeam($teamId: ID!) {\n    leaveTeam(teamId: $teamId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation LeaveTeam($teamId: ID!) {\n    leaveTeam(teamId: $teamId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ManageInvitations($teamId: ID!, $push: [String], $pull: [String]) {\n    manageInvitations(teamId: $teamId, push: $push, pull: $pull) {\n      id\n      invitations\n    }\n  }\n"): (typeof documents)["\n  mutation ManageInvitations($teamId: ID!, $push: [String], $pull: [String]) {\n    manageInvitations(teamId: $teamId, push: $push, pull: $pull) {\n      id\n      invitations\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeTeamMemberRole($teamId: ID!, $userId: ID!, $role: TeamRole!) {\n    changeTeamMemberRole(teamId: $teamId, userId: $userId, role: $role) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeTeamMemberRole($teamId: ID!, $userId: ID!, $role: TeamRole!) {\n    changeTeamMemberRole(teamId: $teamId, userId: $userId, role: $role) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveTeamMember($teamId: ID!, $userId: ID!) {\n    removeTeamMember(teamId: $teamId, userId: $userId) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveTeamMember($teamId: ID!, $userId: ID!) {\n    removeTeamMember(teamId: $teamId, userId: $userId) {\n      id\n      members {\n        role\n        user {\n          email\n          profilePictureUrl\n          uid\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateTeamInvitationLink($teamId: ID!) {\n    generateTeamInvitationLink(teamId: $teamId)\n  }\n"): (typeof documents)["\n  mutation GenerateTeamInvitationLink($teamId: ID!) {\n    generateTeamInvitationLink(teamId: $teamId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation JoinTeam($inviteLinkURL: String!) {\n    joinTeam(inviteLinkURL: $inviteLinkURL) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation JoinTeam($inviteLinkURL: String!) {\n    joinTeam(inviteLinkURL: $inviteLinkURL) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTeam($name: String) {\n    createTeam(name: $name) {\n      id\n      name\n      logo {\n        bucketName\n        fileName\n      }\n  }\n}\n"): (typeof documents)["\n  mutation CreateTeam($name: String) {\n    createTeam(name: $name) {\n      id\n      name\n      logo {\n        bucketName\n        fileName\n      }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListTrendySongTemplateCategoriesWithTemplates {\n    listTrendySongTemplateCategoriesWithTemplates {\n      category {\n        categoryId\n        label\n      }\n      items {\n        _id\n        hasSound\n        isPro\n        isTemplateNew\n        maxMediaNb\n        minMediaNb\n        thumbnailUrl\n        videoUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListTrendySongTemplateCategoriesWithTemplates {\n    listTrendySongTemplateCategoriesWithTemplates {\n      category {\n        categoryId\n        label\n      }\n      items {\n        _id\n        hasSound\n        isPro\n        isTemplateNew\n        maxMediaNb\n        minMediaNb\n        thumbnailUrl\n        videoUrl\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;